/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const TalviretkeilynTurvallisuus = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/verkkokurssit/talviretkeilyn-turvallisuus',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Talviretkeilyn turvallisuus, 2.12.2024 klo 18.30-20.30',
    description:
      'Onko haaveissasi useamman päivän vaellus Lapissa, mutta jostain syystä et ole vielä päässyt toteuttamaan unelmaasi? Nyt sinulla on mahdollisuus laittaa rinkka selkään ja lähteä oppimaan vaelluksen perusteita legendaariselle Hetta-Pallaksen reitille eräoppaan kanssa.',
  };

  return (
    <CampHoc
      heroImage={
        data.talviretkeilynTurvallisuusBg.childImageSharp.gatsbyImageData
      }
      seo={seo}
      camp="talviretkeilyn-turvallisuus"
    >
      <div className="camp-text-content">
        <h2>Talviretkeilyn turvallisuus -verkkokurssi</h2>
        <h3>2.12.2024 klo 18.30-20.30</h3>
        <p className="bolded">
          Johdatus lumiturvalliseen ajatteluun talviretkeilyssä.
        </p>
        <br />
        <p className="bolded">Kenelle verkkokurssi sopii?</p>
        <p>
          Teetkö jo omia tai haaveiletko talviretkistä lumisissa olosuhteissa?
          Tämä verkkokurssi sopii kaiken ikäisille retkeilijöille, niin miehille
          kuin naisille. Talvella retkeillessä on tärkeää olla tietoinen
          erilaisista riskeistä, joita matkalla saattaa olla. Lumi ja kylmyys
          tuovat retkeilyyn omat haasteensa. Koskaan tietoa ei voi olla liikaa
          ja tietämättömyys lisää riskiä. Tieto kantaa lumella.
          <br />
          <br />
          Pohjois-Suomessa tapahtuu joka talvi lumivyöryjä. Jos lähdet
          takamaastoon ja varsinkin merkkaamattomille reiteille, edes hetkeksi,
          ole tietoinen lumivyöryriskeistä.
        </p>
        <br />
        <p className="bolded">
          Verkkokurssin vetäjä, Vanhempi konstaapeli, lumivyöryammattilainen,
          Antte Lauhamaa
        </p>
        <p>
          Lapin poliisilaitoksella työskentelevällä vanhemmalla konstaapelilla
          Antte Lauhamaalla on 25 vuoden kokemus pohjoismaiden erämaista.
          Kilpisjärveltä kotoisin oleva Antte on CAA 2 - sertifioitu
          lumivyöryammattilainen. Antte on toiminut Finlav-kouluttajana vuodesta
          2017 lähtien. Hän pitää lumiturvallisuuskoulutuksia harrastajille ja
          viranomaisille pääosin Ylläksellä, Kilpisjärvellä ja Pohjois-Norjassa.
          Antte tekee myös lumivyöryennusteita Ylläksen Ski Patrollissa.
          <br />
          <br />
          “Olen kasvanut yhdessä talvien kanssa ja ne ovat opettaneet minulle
          syvää kunnioitusta luontoa kohtaan, mutta myös elämän iloa, rakkautta
          elämään ja niihin lyhyisiin hetkiin, jotka liukuvat ohitsemme kuin
          sukset loputtomalla lumirinteellä.”
          <br />
          <br />- Antte Lauhamaa
        </p>
      </div>
      <GatsbyImage
        image={data.antte.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">Verkkotapaamisen sisältö:</p>
        <p>
          - Lumiturvallisuus talviretkeilyssä, lumivyöryajattelu <br />
          - Talviretkeilyn perusturvallisuuteen liittyviä asioita kuten
          esimerkiksi: <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Varusteet, mitä olla
          mukana <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Mihin tulee varautua{' '}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Kenelle ja miten
          kertoa minne olet menossa / missä olet <br />
        </p>
        <br />
        <p>
          <span className="bolded">Talviretkeilyn turvallisuus-</span>
          verkkokurssi järjestetään Zoomissa. Voit käyttää Zoomia tietokoneen
          selaimelta tai voit ladata Zoomin puhelimeesi: <br />
          <br />
          <a
            className="bolded"
            href="https://apps.apple.com/us/app/zoom-workplace/id546505307"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple
          </a>{' '}
          <br />
          <a
            className="bolded"
            href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings&amp;hl=en&amp;pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Android
          </a>{' '}
          <br />
          <br />
          Avaamme linjan 15min ennen tapaamista, jotta sinulla on aikaa tulla
          linjoille. Muistathan tehdä omat muistiinpanot, sillä esitystä ei
          jaeta jälkikäteen, eikä verkkokurssia tallenneta.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.verkkokurssiImg2.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.verkkokurssiImg3.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Hinta 65€ sis alv.</p>
        <p>
          Ilmoittaudu mukaan sähköpostitse otsikolla Talviretkeilyn turvallisuus
          -verkkokurssi osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          . Paluupostina saat verkkokurssin laskun. Kun lasku on maksettu, saat
          Zoom-linkin.
        </p>
        <br />
        <p className="bolded">Peruutusehdot:</p>
        <p>
          Jos perut osallistumisesi verkkokussille missä kohtaa tahansa ennen
          kuin verkkokurssi käynnistyy klo 18.30, pidätämme maksetusta laskusta
          toimistokulut 50€. Ilmoittautumisen peruutus tulee tehdä sähköpostitse
          osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
          <br />
          <br />
          Kuluttajansuojalain 16 § mukaan digitaalisen tuotteen palautusoikeus
          raukeaa, kun sisällön toimittaminen on todennettavasti aloitettu.
          Peruuttamisoikeus ei koske mahdollisia käyttäjän omien teknisten
          taitojen tai laiteongelmien johdosta johtuvia vaikeuksia saada ostettu
          verkkokurssituote käyttöön, mikäli verkkokurssin suorittaminen on
          aloitettu.
        </p>
        <br />
        <p className="bolded">
          Talviretkeilyn turvallisuus -verkkokurssi 2.12.2024 klo 18.30-20.30 –
          lämpimästi tervetuloa mukaan!
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    talviretkeilynTurvallisuusBg: file(
      relativePath: { eq: "images/verkkokurssi/verkkokurssi-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    verkkokurssiImg1: file(
      relativePath: { eq: "images/verkkokurssi/verkkokurssi-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    verkkokurssiImg2: file(
      relativePath: { eq: "images/verkkokurssi/verkkokurssi-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    verkkokurssiImg3: file(
      relativePath: { eq: "images/verkkokurssi/verkkokurssi-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    antte: file(relativePath: { eq: "images/antte.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/verkkokurssi/verkkokurssi-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default TalviretkeilynTurvallisuus;
